import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import styling from '../../styling';
import TwitterIconPath from '../../media/icons/twitter-brands-dark.svg';

const StyledFollow = styled.div`
  margin-bottom: 2em;
  display: flex;
  align-items: center;

  a {
    display: inline-flex;
    align-items: center;

    > img {
      margin-left: 0.5em;
      height: 1em;
      line-height: 1;
    }
  }
`;

const StyledEmailSignup = styled.div`
  padding-bottom: 2em;
`;

const StyledNewsletterText = styled.p`
  font-size: 0.9em;
  font-weight: 500;
`;

const StyledEmailSignupForm = styled.form``;

const StyledFloatingLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${styling.sizes.borderRadius};
  background-color: ${styling.colors.primary.L95};
  border: 1px solid ${styling.colors.primary.L95};
  overflow: hidden;

  &:focus-within {
    border-color: ${styling.colors.primary.normal};
  }

  label {
    padding-left: 1rem;
    font-size: 0.8em;
    font-weight: bold;
  }

  input {
    padding: 0.5em 1em;
    border: none;
    background-color: ${styling.colors.primary.L90};

    &:focus {
      outline: none;
    }
  }
`;

const StyledGdprDescription = styled.p`
  font-size: 0.8em;
  margin: 0 !important;
`;

const StyledSubscribeRow = styled.div`
  margin-top: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 40em) {
    flex-wrap: wrap;
  }

  ${StyledGdprDescription} {
  }

  input {
    margin-right: 0.5em;
    padding: 0.5em 1em;
    border: none;
    border-radius: ${styling.sizes.borderRadius};
    background-color: ${styling.colors.primary.normal};
    color: white;
  }
`;

interface Props {
  article: Article;
}

class Follow extends Component<Props> {
  public render() {
    const article = this.props.article;
    const followText = article.frontmatter.newsletterSignupText != null ? 'Or follow' : 'Follow';
    const follow = (
      <StyledFollow>
        <span>
          {followText} me on{' '}
          <a href="https://twitter.com/Domysee" target="_blank">
            Twitter
            <img src={TwitterIconPath} />
          </a>{' '}
          for more of my thoughts, articles, projects and work.
        </span>
      </StyledFollow>
    );
    const newsletterText =
      article.frontmatter.newsletterSignupText != null ? (
        <StyledNewsletterText>{article.frontmatter.newsletterSignupText}</StyledNewsletterText>
      ) : (
        <p>Or sign up for my newsletter, to get new articles directly to your inbox.</p>
      );
    const newsletter = (
      <StyledEmailSignup>
        {newsletterText}
        <StyledEmailSignupForm
          action="https://dominik-weber.us10.list-manage.com/subscribe/post?u=290078e226e6d98a1c36614dd&amp;id=4dd5630e6e"
          method="post"
          name="mc-embedded-subscribe-form"
          target="_blank">
          <div>
            <StyledFloatingLabelInput>
              <label htmlFor="mce-EMAIL">Email Address</label>
              <input type="email" name="EMAIL" required />
            </StyledFloatingLabelInput>
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input type="text" name="b_290078e226e6d98a1c36614dd_4dd5630e6e" tabIndex={-1} />
            </div>
            <StyledSubscribeRow>
              <input type="submit" value="Subscribe" name="subscribe" />
              <StyledGdprDescription>
                I use{' '}
                <a href="https://mailchimp.com/legal/" target="_blank">
                  Mailchimp
                </a>{' '}
                for newsletter sending, which means your email address will be transferred to them.
              </StyledGdprDescription>
            </StyledSubscribeRow>
          </div>
        </StyledEmailSignupForm>
      </StyledEmailSignup>
    );
    if (article.frontmatter.prioritizeNewsletterSignup === true) {
      return (
        <Fragment>
          {newsletter}
          {follow}
        </Fragment>
      );
    }
    return <Fragment>{follow}</Fragment>;
  }
}

export default Follow;
