import Layout from '../layout';
import React, { Component } from 'react';
import styled from 'styled-components';
import styling from '../../styling';
import { Page } from '../layout/header';
import SingleArticleTop from './singleArticleTop';
import SeriesArticleTop from './seriesArticleTop';
import Follow from './follow';
import TimelineComponent from './timeline';
import RelatedArticles from './relatedArticles';
import { MDXRenderer } from 'gatsby-plugin-mdx';

//#region Styled Components
const StyledArticle = styled.article`
  h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.75em;
    line-height: 1.25;

    + div > p:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1.1em;
  }

  h5 {
    font-size: 1em;
  }

  h6 {
    font-size: 1em;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2rem;
    margin-bottom: 0.5em;
    line-height: 1.5;

    + p {
      margin-top: 0;
    }
  }

  pre {
    overflow: auto;
  }

  blockquote {
    margin: 2em -2em;
    padding: 2em;
    font-style: italic;
    background-color: ${styling.colors.gray.L95};
    border-radius: ${styling.sizes.borderRadius};

    p {
      margin: 0;
    }

    @media (max-width: ${styling.sizes.contentWidthNumber * 0.6}em) {
      margin-left: -${styling.sizes.contentWidthPaddingNumber * 0.2}em;
      margin-right: -${styling.sizes.contentWidthPaddingNumber * 0.2}em;
      padding-left: ${styling.sizes.contentWidthPaddingNumber * 0.2}em;
      padding-right: ${styling.sizes.contentWidthPaddingNumber * 0.2}em;
    }

    @media (max-width: ${styling.sizes.contentWidthNumber * 0.5}em) {
      margin-left: -1em;
      margin-right: -1em;
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  p {
    margin-top: 2em;
    margin-bottom: 0;
  }

  .cp_embed_wrapper {
    margin-bottom: 1em;
  }

  ul {
    margin-top: 0.25em;
  }

  li {
    margin: 0.25em 0;

    > p {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
  }
`;

const StyledExtraContentContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  transform: translateX(0);
  display: flex;
  justify-content: center;
  /* pointer-events: none; */

  @media (max-width: ${styling.sizes.contentWidthNumber * 0.8}em) {
    display: none;
  }

  > div {
    padding: 0;
    width: 100%;
    max-width: ${styling.sizes.contentWidth};
    ${styling.sizes.adaptedSizes}
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
`;

const StyledSeparation = styled.hr`
  margin: 2em 4em;
  border: none;
  border-bottom: 1px solid ${styling.colors.primary.L80};
`;
//#endregion

interface Props {
  location: Location;
  article: Article;
  series?: Series;
  seriesInfo?: SeriesInfo;
  relatedArticles: ArticleOverview[];
}

const getScripts = (astRoot: any): string[] => {
  if (astRoot == null) {
    return [];
  }

  if (astRoot.children != null && astRoot.children.length > 0) {
    return astRoot.children.reduce((links: string[], ast: any) => links.concat(getScripts(ast)), []);
  }

  if (astRoot.tagName === 'script') {
    return [astRoot.properties.src];
  }

  return [];
};

class Blogpost extends Component<Props> {
  private renderArticleTop() {
    if (this.props.series != null) {
      return (
        <SeriesArticleTop article={this.props.article} series={this.props.series} seriesInfo={this.props.seriesInfo} />
      );
    }
    return <SingleArticleTop article={this.props.article} />;
  }

  public render() {
    const article = this.props.article;
    return (
      <Layout
        currentPage={Page.Blogpost}
        title={article.frontmatter.title}
        keywords={article.frontmatter.tags}
        description={article.excerpt}
        location={this.props.location}>
        <main>
          <StyledArticle>
            <StyledExtraContentContainer>
              <div>
                <TimelineComponent timeline={article.frontmatter.timeline} />
                <RelatedArticles relatedArticles={this.props.relatedArticles} />
              </div>
            </StyledExtraContentContainer>
            {this.renderArticleTop()}
            <MDXRenderer>{article.body}</MDXRenderer>
            <StyledSeparation />
            <Follow article={article} />
          </StyledArticle>
        </main>
      </Layout>
    );
  }
}

interface PageProps {
  location: Location;
  pageContext: {
    article: Article;
    series: Series;
    seriesInfo: SeriesInfo;
    relatedArticles: ArticleOverview[];
  };
}

const Wrapper: React.SFC<PageProps> = ({ location, pageContext: { article, series, seriesInfo, relatedArticles } }) => (
  <Blogpost
    location={location}
    article={article}
    series={series}
    seriesInfo={seriesInfo}
    relatedArticles={relatedArticles}
  />
);

export default Wrapper;
