import React, { FunctionComponent, Fragment } from 'react';
import styled from 'styled-components';
import styling from '../../styling';

const StyledExtraInformation = styled.div`
  margin-top: 2em;
  display: flex;
  color: ${styling.colors.gray.L54};
  line-height: 1;

  > small {
    font-size: 0.9em;

      :first-child {
        margin-right: 0.5rem;
      }
  }
`;

interface Props {
  article: ArticleOverview;
}

const SingleArticleTop: FunctionComponent<Props> = ({ article }) => (
  <Fragment>
    <StyledExtraInformation>
      <small>{article.frontmatter.date}</small>
      <small>({article.timeToRead} min)</small>
    </StyledExtraInformation>
    <h1>{article.frontmatter.title}</h1>
  </Fragment>
);

export default SingleArticleTop;
