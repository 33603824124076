import React, { Component, Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import styling from '../../styling';
import { Link } from 'gatsby';

const StyledRelatedarticles = styled.aside`
  width: 20em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: -3em;
  transform: translateX(100%);
  font-size: 0.8em;
  border-radius: ${styling.sizes.borderRadius};
  padding: 1em;
  background-color: white;
  box-shadow: ${styling.miscellaneous.shadowLevel1};

  @media (max-width: 104rem) {
    display: none;
  }

  > h2 {
    margin-top: 0 !important;
    font-size: 1rem !important;
  }
`;

const StyledLink = styled(Link)`
  margin: 0.5em 0;
  display: block;
`;

interface Props {
  relatedArticles: ArticleOverview[];
}

class TimelineComponent extends Component<Props> {
  public render() {
    if (this.props.relatedArticles.length === 0) {
      return null;
    }

    return (
      <StyledRelatedarticles>
        <h2>Related Articles</h2>
        <div>
          {this.props.relatedArticles.slice(0, 3).map((article) => (
            <StyledLink to={`/blogposts/${article.frontmatter.slug}`}>{article.frontmatter.title}</StyledLink>
          ))}
        </div>
      </StyledRelatedarticles>
    );
  }
}

export default TimelineComponent;
