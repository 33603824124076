import React, { FunctionComponent, Fragment } from 'react';
import styled from 'styled-components';
import styling from '../../styling';
import { Link } from 'gatsby';

const StyledExtraInformation = styled.div`
  margin-top: 2em;
  display: flex;
  color: ${styling.colors.gray.L54};
  line-height: 1;

  > small {
    font-size: 0.9em;

      :first-child {
        margin-right: 0.5rem;
      }
  }
`;

const StyledSeriesArticleLinks = styled.div`
  margin-top: 1em;
  margin-bottom: 3em;
  display: flex;
  flex-direction: column;
`;

const StyledSeriesArticleLink = styled(Link)``;

const StyledSeriesArticleCurrent = styled.span``;

interface Props {
  article: ArticleOverview;
  series: Series;
  seriesInfo: SeriesInfo;
}

const SeriesArticleTop: FunctionComponent<Props> = ({ article, series, seriesInfo }) => (
  <Fragment>
    <StyledExtraInformation>
      <small>{article.frontmatter.date}</small>
      <small>({article.timeToRead} min)</small>
    </StyledExtraInformation>
    <h1>
      {series.frontmatter.title}: {article.frontmatter.title}
    </h1>
    <div dangerouslySetInnerHTML={{ __html: series.html }} />
    <StyledSeriesArticleLinks>
      {seriesInfo.articles.map((seriesArticle) =>
        seriesArticle.frontmatter.slug === article.frontmatter.slug ? (
          <StyledSeriesArticleCurrent>
            {series.frontmatter.title} - {seriesArticle.frontmatter.title}
          </StyledSeriesArticleCurrent>
        ) : (
          <StyledSeriesArticleLink to={`/blogposts/${seriesArticle.frontmatter.slug}`}>
            {series.frontmatter.title} - {seriesArticle.frontmatter.title}
          </StyledSeriesArticleLink>
        )
      )}
    </StyledSeriesArticleLinks>
  </Fragment>
);

export default SeriesArticleTop;
