import React, { Component, Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import styling from '../../styling';

const transitionInNext = keyframes`
  from {
      opacity: 0;
      transform: translateY(-2em);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

const transitionInPrevious = keyframes`
  from {
      opacity: 0;
      transform: translateY(2em);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

const transitionOutNext = keyframes`
  from {
      opacity: 1;
      transform: translateY(0);
  }
  to {
      opacity: 0;
      transform: translateY(2em);
  }
`;

const transitionOutPrevious = keyframes`
  from {
      opacity: 1;
      transform: translateY(0);
  }
  to {
      opacity: 0;
      transform: translateY(-2em);
  }
`;

const StyledTimeline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: -3em;
  transform: translateX(-100%);
  font-size: 0.8em;
  border-radius: ${styling.sizes.borderRadius};
  padding: 1em;
  background-color: white;
  box-shadow: ${styling.miscellaneous.shadowLevel1};

  @media (max-width: 89em) {
    display: none;
  }
`;

interface StyledPointProps {
  current: boolean;
  transitionInNext: boolean;
  transitionInPrevious: boolean;
  transitionOutNext: boolean;
  transitionOutPrevious: boolean;
}

const getAnimation = (props: StyledPointProps) => {
  if (props.transitionInNext) {
    return transitionInNext;
  }
  if (props.transitionInPrevious) {
    return transitionInPrevious;
  }
  if (props.transitionOutNext) {
    return transitionOutNext;
  }
  if (props.transitionOutPrevious) {
    return transitionOutPrevious;
  }
  return null;
};

const timelineColor = styling.colors.primary.L60;

const StyledPoint = styled.div<StyledPointProps>`
  display: flex;
  align-items: center;

  > div:first-child {
    margin-right: 0.5em;
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    border: 2px solid ${timelineColor};
    border-radius: 100%;
    background-color: ${(props) => (props.current ? timelineColor : 'white')};
    transition: all 0.25s ease-in-out;
  }

  > div:last-child {
    white-space: nowrap;
    line-height: 1;
    color: ${styling.colors.text.headline};
    font-weight: 500;
    ${(props) =>
      !props.current &&
      !props.transitionInNext &&
      !props.transitionInPrevious &&
      !props.transitionOutNext &&
      !props.transitionOutPrevious &&
      'visibility: hidden;'}
    ${(props) =>
      getAnimation(props) != null &&
      css`
        animation: ${getAnimation(props)} 0.25s linear forwards;
      `}
  }
`;

const StyledLine = styled.div`
  margin-left: calc(0.5em - 1px);
  width: 2px;
  height: 1em;
  background-color: ${timelineColor};
`;

interface Props {
  timeline?: Timeline;
}

interface State {
  intersectionObserverSupported: boolean;
  previousEntry: TimelineEntry;
  currentEntry: TimelineEntry;
}

class TimelineComponent extends Component<Props, State> {
  public state: State = {
    intersectionObserverSupported: false,
    previousEntry: null,
    currentEntry: null
  };

  private intersectionObserver: IntersectionObserver = null;

  public componentDidMount() {
    if (this.props.timeline == null || this.props.timeline.length === 0) {
      return;
    }

    this.setState({
      intersectionObserverSupported: (window as any).IntersectionObserver != null,
      previousEntry: null,
      currentEntry: this.props.timeline[0]
    });
    this.intersectionObserver = new IntersectionObserver((entries) => {
      const intersectingElementsHeadlines = entries.filter((e) => e.isIntersecting).map((e) => e.target.textContent);
      const intersectingEntries = this.props.timeline.filter((te) =>
        intersectingElementsHeadlines.includes(te.headline)
      );
      const lastIntersectingEntry = intersectingEntries[intersectingEntries.length - 1];
      if (lastIntersectingEntry != null) {
        this.setState({
          currentEntry: lastIntersectingEntry,
          previousEntry: this.state.currentEntry
        });
      }
    });
    const elements = document.querySelectorAll(
      'main > article h1, main > article h2, main > article h3, main > article h4, main > article h5, main > article h6'
    );
    elements.forEach((e) => this.intersectionObserver.observe(e));
  }

  public componentWillUnmount() {
    if (this.intersectionObserver != null) {
      this.intersectionObserver.disconnect();
    }
  }

  public render() {
    if (this.props.timeline == null || this.props.timeline.length === 0 || !this.state.intersectionObserverSupported) {
      return null;
    }
    const previousEntryIndex = this.props.timeline.indexOf(this.state.previousEntry);
    const currentEntryIndex = this.props.timeline.indexOf(this.state.currentEntry);
    return (
      <StyledTimeline>
        {this.props.timeline.reduce((nodes, timelineEntry, i) => {
          const current = timelineEntry === this.state.currentEntry;
          const shouldTransitionInNext = current && i === previousEntryIndex + 1;
          const shouldTransitionInPrevious = current && i === previousEntryIndex - 1;
          const shouldTransitionOutNext = i === previousEntryIndex && previousEntryIndex < currentEntryIndex;
          const shouldTransitionOutPrevious = i === previousEntryIndex && previousEntryIndex > currentEntryIndex;
          const point = (
            <StyledPoint
              current={current}
              transitionInNext={shouldTransitionInNext}
              transitionInPrevious={shouldTransitionInPrevious}
              transitionOutNext={shouldTransitionOutNext}
              transitionOutPrevious={shouldTransitionOutPrevious}>
              <div />
              <div>{timelineEntry.date}</div>
            </StyledPoint>
          );
          const line = <StyledLine />;
          if (i < this.props.timeline.length - 1) {
            return nodes.concat([point, line]);
          }
          return nodes.concat([point]);
        }, [])}
      </StyledTimeline>
    );
  }
}

export default TimelineComponent;
